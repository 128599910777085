// read-only JSEditor
.js {
  &.editor { 
    background-color: #ffffcc;
    margin-bottom: 1em;
  }

  .cm-wrap {
     background-color: #ffffdd;
     height: 100%;

    .cm-content .cm-activeLine {
      background-color: #ffffcc;
    }
  }
}

.js.exception, .demo-results + .exception {
  background-color:#ff0;
  margin: 1em 0;
  padding: 1em;
  border: 4px solid red;
  border-radius: 1em;
}

// Ruby editor
.ruby {
  &.editor {
    resize: vertical;
    overflow: auto;
    background-color: #ffeeee;
    margin-bottom: 1em;
  }

  .cm-wrap {
    background-color: #ffeeee;
    height: 100%;

    .cm-content .cm-activeLine {
      background-color: #ffdddd;
      margin-right: 2px;
    }
  }
}

// Demo results
.demo-results {
  border: 1px solid black;
  padding: 0.5rem;
  width: 100%;
  resize: vertical;
  overflow: auto;
}

// AST output
#parsed, #filtered {
  .unloc {
    background-color: yellow;
  }

  .loc {
    background-color: white;
  }

  .unloc, .loc {
    span.hidden {
      font-size: 0;
    }
  }
}

// demo page specific formatting
.container.narrow-container {
  padding: 0;
  margin: 0 3%;
  max-width: 91%;

  .ruby.editor {
    height: 10rem;
  }

  [data-controller="options"] {
    display: flex;
    gap: 0.8rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  [for="eslevel"] {
    position: relative;
    right: -0.35em;
  }

  pre {
    padding: 0 1rem;
  }

  h1.title, h2.title {
    margin: 1rem;
  }

  sl-menu {
    display: none;
  }
}
