.button,
.control.has-icons-left .icon,
.control.has-icons-right .icon,
.input,
.pagination-ellipsis,
.pagination-link,
.pagination-next,
.pagination-previous,
.select:not(.is-multiple),
.select:not(.is-multiple) select,
.textarea {
  height: 2.572em;
}

.button:not(.has-mixed-case) {
  text-transform: uppercase;
}

.notification {
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      a:not(.button) {
        color: $color-invert;
        text-decoration: underline;
      }
    }
  }
}

.navbar .ruby2js-logo {
  transition: opacity 0.2s ease-in-out;
}

a,
.button {
  transition: 0.2s ease-in-out;
  transition-property: background, background-color, color;
}

.button.is-large {
  font-weight: 700;
}

body.post .button.is-small {
  height: auto;
  white-space: normal;
}

@keyframes fade-in-button {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

#mobile-nav-activator {
  @include tablet {
    display: none;
  }
  height: auto;
  position: fixed;
  z-index: 10;
  bottom: 60px;
  right: 12px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.3);
  animation-name: fade-in-button;
  animation-duration: 0.75s;

  .not-shown {
    display: none;
  }
  .shown {
    animation-name: fade-in-button;
    animation-duration: 0.45s;
  }
}

nav .search-item input {
  font-size: 1em;
  height: 1.85em;
  padding: 0.2em 0.6em;
  background: white;
  opacity: 0.8;
  width: 95%;
  max-width: 400px;
  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  &::placeholder {
    color: #888;
    opacity: 1;
  }

  border: none;
  &:focus {
    opacity: 1;
  }

  @include desktop {
    width: calc(9vw * 2);
  }
}
bridgetown-search-results {
  --link-color: #{$link};
  --divider-color: var(--sl-color-gray-200);
  --text-color: var(--sl-color-gray-700);
}
