/*.pagination-link,
.pagination-next,
.pagination-previous {
  color: $primary;
  background-color: $pagination-background-color;
} */

h1,
h2,
h3 {
  letter-spacing: -0.05em;
}

body.home main {
  h1.title {
    font-size: 4rem;
    @media screen and (max-width: 32rem) {
      font-size: 15.5vw;
    }
  }
  ul {
    margin-left: 3.5em;
    list-style-type: none;
    ui-icon {
      position: absolute;
      margin-left: -3em;
    }
    @include mobile {
      margin-left: 10vw;
      ui-icon {
        margin-left: -2.5em;
      }
    }
    @include widescreen {
      margin-left: 4.5em;
    }

    .is-size-5 {
      margin-right: 0.2em;
    }
  }
}

.footer a {
  color: $yellow;
  &:hover {
    color: $orange;
  }
}

.minimal-line-height pre {
  line-height: 135%;
}

h2 .tag {
  margin-bottom: 2px;
  margin-left: 0.6em;
  letter-spacing: normal;
  font-weight: 500;
}

.functions-list {
  li > code:first-child {
    font-weight: bold;
  }
}