$base-code-text-color: #403030;

.content pre {
  border-radius: 4px;
}

.highlight .hll { background-color: #ffffcc }
.highlight .c { color: $grey; font-style: italic } /* Comment */
.highlight .err { color: #a40000; border: 1px solid #ef2929 } /* Error */
.highlight .g { color: $base-code-text-color } /* Generic */
.highlight .k { color: #204a87; font-weight: 500 } /* Keyword */
.highlight .l { color: $base-code-text-color } /* Literal */
.highlight .n { color: $base-code-text-color } /* Name */
.highlight .o { color: #ce5c00; font-weight: 500 } /* Operator */
.highlight .x { color: $base-code-text-color } /* Other */
.highlight .p { color: $base-code-text-color; font-weight: 500 } /* Punctuation */
.highlight .cm { color: $grey; font-style: italic } /* Comment.Multiline */
.highlight .cp { color: $grey; font-style: italic } /* Comment.Preproc */
.highlight .c1 { color: $grey; font-style: italic } /* Comment.Single */
.highlight .cs { color: $grey; font-style: italic } /* Comment.Special */
.highlight .gd { color: #a40000 } /* Generic.Deleted */
.highlight .ge { color: $base-code-text-color; font-style: italic } /* Generic.Emph */
.highlight .gr { color: #ef2929 } /* Generic.Error */
.highlight .gh { color: #000080; font-weight: 500 } /* Generic.Heading */
.highlight .gi { color: #00A000 } /* Generic.Inserted */
.highlight .go { color: $base-code-text-color; font-style: italic } /* Generic.Output */
.highlight .gp { color: $grey } /* Generic.Prompt */
.highlight .gs { color: $base-code-text-color; font-weight: 500 } /* Generic.Strong */
.highlight .gu { color: #800080; font-weight: 500 } /* Generic.Subheading */
.highlight .gt { color: #a40000; font-weight: 500 } /* Generic.Traceback */
.highlight .kc { color: #204a87; font-weight: 500 } /* Keyword.Constant */
.highlight .kd { color: #204a87; font-weight: 500 } /* Keyword.Declaration */
.highlight .kn { color: #204a87; font-weight: 500 } /* Keyword.Namespace */
.highlight .kp { color: #204a87; font-weight: 500 } /* Keyword.Pseudo */
.highlight .kr { color: #204a87; font-weight: 500 } /* Keyword.Reserved */
.highlight .kt { color: #204a87; font-weight: 500 } /* Keyword.Type */
.highlight .ld { color: $base-code-text-color } /* Literal.Date */
.highlight .m { color: #0000cf; font-weight: 500 } /* Literal.Number */
.highlight .s { color: #4e9a06 } /* Literal.String */
.highlight .na { color: #8d8400 } /* Name.Attribute */
.highlight .nb { color: #204a87 } /* Name.Builtin */
.highlight .nc { color: $base-code-text-color } /* Name.Class */
.highlight .no { color: $base-code-text-color } /* Name.Constant */
.highlight .nd { color: #5c35cc; font-weight: 500 } /* Name.Decorator */
.highlight .ni { color: #ce5c00 } /* Name.Entity */
.highlight .ne { color: #cc0000; font-weight: 500 } /* Name.Exception */
.highlight .nf { color: $base-code-text-color } /* Name.Function */
.highlight .nl { color: #f57900 } /* Name.Label */
.highlight .nn { color: $base-code-text-color } /* Name.Namespace */
.highlight .nx { color: $base-code-text-color } /* Name.Other */
.highlight .py { color: $base-code-text-color } /* Name.Property */
.highlight .nt { color: #204a87; font-weight: 500 } /* Name.Tag */
.highlight .nv { color: $base-code-text-color } /* Name.Variable */
.highlight .ow { color: #204a87; font-weight: 500 } /* Operator.Word */
.highlight .w { color: #f8f8f8; text-decoration: underline } /* Text.Whitespace */
.highlight .mf { color: #0000cf; font-weight: 500 } /* Literal.Number.Float */
.highlight .mh { color: #0000cf; font-weight: 500 } /* Literal.Number.Hex */
.highlight .mi { color: #0000cf; font-weight: 500 } /* Literal.Number.Integer */
.highlight .mo { color: #0000cf; font-weight: 500 } /* Literal.Number.Oct */
.highlight .sb { color: #4e9a06 } /* Literal.String.Backtick */
.highlight .sc { color: #4e9a06 } /* Literal.String.Char */
.highlight .sd { color: $grey; font-style: italic } /* Literal.String.Doc */
.highlight .s2 { color: #4e9a06 } /* Literal.String.Double */
.highlight .se { color: #4e9a06 } /* Literal.String.Escape */
.highlight .sh { color: #4e9a06 } /* Literal.String.Heredoc */
.highlight .si { color: #4e9a06 } /* Literal.String.Interpol */
.highlight .sx { color: #4e9a06 } /* Literal.String.Other */
.highlight .sr { color: #4e9a06 } /* Literal.String.Regex */
.highlight .s1 { color: #4e9a06 } /* Literal.String.Single */
.highlight .ss { color: #4e9a06 } /* Literal.String.Symbol */
.highlight .bp { color: #3465a4 } /* Name.Builtin.Pseudo */
.highlight .vc { color: $base-code-text-color } /* Name.Variable.Class */
.highlight .vg { color: $base-code-text-color } /* Name.Variable.Global */
.highlight .vi { color: $base-code-text-color } /* Name.Variable.Instance */
.highlight .il { color: #0000cf; font-weight: 500 } /* Literal.Number.Integer.Long */

.language-liquid {
  .highlight .p {
    color: #8d8400;
  }
  .highlight .nv {
    color: #204a87;
  }
}
