/* Import the base Shoelace stylesheet: */
@import "@shoelace-style/shoelace/dist/themes/light.css";

$grey-darker: #2a2a26;
$grey-dark: #3e3e3e;
$grey: #888;
$grey-light: #c9c8b8;
$grey-lighter: #e6e1d7;

$white-ter: #f6f6f6;

$yellow: #ffd725;
$orange: #f88a23;
$green: #7addcf;
$blue: #29abe0;
$red: #c92514;
$red-dark: #460f0e;
.has-text-red-dark {
  color: $red-dark !important;
}

$primary: #242341 !default;
$info: $red;
$warning: $orange;
$warning-invert: #fff;
$code: $primary;

$scheme-main: $white-ter;

$family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
$family-monospace: ui-monospace, monospace;
$body-size: 18px;
$title-weight: 700;
$content-heading-weight: 700;

$link: $red-dark;

$pagination-background-color: $grey-lighter;

$navbar-background-color: $primary;
$navbar-item-color: $white-ter;
$navbar-item-hover-color: white;
$navbar-item-active-color: white;
$navbar-item-active-background-color: darken($primary, 5%);
$navbar-item-hover-background-color: $navbar-item-active-background-color;
$navbar-dropdown-arrow: $navbar-item-color;

$card-shadow: 0 2px 3px rgba($grey-dark, 0.1), 0 0 0 1px rgba($grey-dark, 0.1);

$box-background-color: white;

$footer-background-color: $primary;
$footer-color: white;
footer.footer strong {
  color: white;
}

@import "custom_elements.scss";

@import "~bulma/bulma";
@import "helpers.scss";
@import "content.scss";
@import "controls.scss";
@import "layout.scss";
@import "syntax.scss";
@import "typography.scss";

@import "../../src/_components/docs/note.scss";
@import "../../src/_components/shared/navbar.scss";

@import "livedemo.scss";
