.container {
  @include touch {
    /* This fixes a horizontal overscroll bug in iOS Safari */
    overflow: hidden;
  }
  @include mobile {
    main & {
      /* Padding here fixes missing box-shadows */
      padding-top: 2rem;
      margin-top: 0 !important;
      padding-bottom: 2rem;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}

nav > .container {
  @include touch {
    overflow: visible;
  }
}

.hero {
  // Colors
  .navbar {
    background-color: $grey-dark;
  }
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      .navbar {
        background: none;
      }
    }
  }
}

body > footer.footer {
  margin-top: 8rem;
  padding-bottom: 4rem;
}

.box img-container.ruby2js-logo {
  max-width: 275px;
  margin: 1rem auto;

  @media (max-width: 500px) {
    max-width: 215px;
  }

  svg {
    -webkit-filter: drop-shadow(2px 4px 4px transparentize($red, 0.7));
    filter: drop-shadow(2px 4px 4px transparentize($red, 0.7));
  }

  svg path {
    fill: $primary;
  }
}

.footer .ruby2js-logo {
  width: 160px;
  margin-bottom: 0.9rem;

  svg {
    -webkit-filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.3));
    filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.3));
  }

  svg path {
    fill: white;
  }
}

.ruby2js-logo.inverse {
  svg path {
    fill: white !important;
  }
}
